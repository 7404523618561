import React from "react";
import { MdDiscount } from "react-icons/md";
import { toast } from "react-hot-toast";
import { useState } from "react";
import "./Coupons.css";

import { useUserData } from "../../../../contexts/UserDataProvider.js";
import { CgChevronDoubleDown, CgChevronDown } from "react-icons/cg";

export const Shipping = ({ deliveryMethod, setDeliveryMethod }) => {
  const [isDeliveryClick, setDeliveryClick] = useState(true);
  const { userDataState } = useUserData();

  const deliveryOptions = [
    // {
    //   id: 0,
    //   deliveryType: "Pickup",
    //   description: "Choose this option to pick up your order from our partnered vendor's location.",
    //   amount: 0,
    // },
    {
      id: 1,
      deliveryType: "Motor park pickup",
      description: "Use this option for motor park pickup",
      amount: 1500,
    },
    // {
    //   id: 2,
    //   deliveryType: "₦6,000 Motor park and Home delivery",
    //   description: "Use this option for Motor park and Home Delivery",
    //   amount: 6000,
    // },
    {
      id: 3,
      deliveryType: "Airport pickup",
      description: "Use this option for Airport pickup",
      amount: 10000,
    },
    // {
    //   id: 4,
    //   deliveryType: "₦13,000 Airport and Home delivery",
    //   description: "Use this option for Airport and Home Delivery",
    //   amount: 13000,
    // },
    // {
    //   id: 2,
    //   name: "NEW YEAR OFFER",
    //   description: "Get 20% off on a purchase of N500",
    //   minimumPurchase: 500,
    //   discount: 20,
    // },
  ];

  const deliveryHandler = (e, method) => {
    if (e.target.checked) {
      toast.success(`Woohoo! ${method.deliveryType} applied successfully!`);
      setDeliveryMethod(method);
    } else {
      // toast.success(`${method.name} removed!`);
      // setDeliveryMethod({});
    }
  };

  const totalDiscountedPriceBeforeCoupon = userDataState.cartProducts?.reduce(
    (acc, curr) => acc + curr.productId?.discounted_price * curr.qty,
    0
  );
  return (
    <div className="coupons-section">
      <div className="coupon-header">
        <CgChevronDown color={"black"} />
        <h3 onClick={() => setDeliveryClick(!isDeliveryClick)}>
          Select Delivery Method
        </h3>
      </div>

      {isDeliveryClick && (
        <div className="coupon-list-container">
          {deliveryOptions.map((method) => {
            const { id, deliveryType, description, minimumPurchase, discount, amount } =
              method;
            return (
              <div key={id} className="coupon-card">
                <input
                  checked={deliveryMethod?.id == id}
                  onChange={(e) => deliveryHandler(e, method)}
                  disabled={totalDiscountedPriceBeforeCoupon <= minimumPurchase}
                  id={deliveryType}
                  type="checkbox"
                />
                <label htmlFor={deliveryType}>
                  <p className="name">{deliveryType}</p>
                  <p className="description">{description}</p>
                </label>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
